<template>
  <div>
    <div class="row">
      <loading-notification :show="loading" />
      <retry-notification
        :show="error"
        @retry="updateData()"
      />
    </div>
    <div class="row">
      <div class="flex xs12 md4">
        <va-card :title="$t('notifications.page.view')">
          <actions
            slot="actions"
            crud-links="notifications"
            :actions="actions"
            :action-data="{id: $route.params.id}"
          />
          <text-list
            v-if="notification.user"
            :condition="currentUser.can('Users', 'view')"
            :title="$t('tables.headings.receiver')"
            :label="notification.user.name"
            :to="{ name: 'usersView', params: {id: notification.user.id}}"
          />
          <text-list :title="$t('tables.headings.status')">
            {{ $t(getNotificationStatus(notification.status)) }}
          </text-list>
          <text-list
            v-show="notification.created_at"
            :title="$t('tables.headings.date')"
          >
            {{ notification.created_at | date }}
          </text-list>
        </va-card>
      </div>
      <div class="flex xs12 md8">
        <va-card :title="$t('tables.headings.message')">
          {{ notification.message }}
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const TextList = () => import(/* webpackPrefetch: true */ '@/components/extras/Text/TextList')

export default {
  name: 'notifications-view',
  components: {
    Actions,
    TextList,
  },
  data () {
    return {
      error: false,
      notification: {},
      actions: ['index', 'new', 'delete'],
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    notificationsStatus () {
      return [
        { name: this.$t('notifications.status.unreaded'), value: 0 },
        { name: this.$t('notifications.status.readed'), value: 1 },
      ]
    },
  },
  created () {
    this.updateData()
  },
  methods: {
    getNotificationStatus (statusId) {
      let status = this.notificationsStatus.find(s => s.value === statusId)
      status = status || { name: '', value: -1 }

      return status.name
    },
    routeBuilder (id) {
      return `notifications/${id}`
    },
    async updateData () {
      this.loading = true
      this.error = false

      let u = false
      const notificationsId = this.$route.params.id
      try {
        u = await this.$http.get(this.routeBuilder(notificationsId))
      } catch (err) {
        // console.log('Error fetching notifications data', err)
        this.error = true
        this.loading = false
        return
      }

      this.loading = false
      this.notification = u.data.data
    },
    async prepareDownload (format) {
      const prepareRoute = this.routeBuilder(this.notification.id) + '?export=' + format
      const downloadRoute = 'notifications/download/'

      return this.downloadFile(prepareRoute, downloadRoute)
    },
    async downloadFile (prepareRoute, downloadRoute) {
      let fileName = ''
      const prepare = {
        icon: 'info',
        title: this.$t('notifications.download.prepare'),
        confirmButtonText: this.$t('notifications.download.button'),
        text: this.$t('notifications.download.wait'),
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        preConfirm: async () => {
          let data = false
          try {
            data = await this.$http.get(prepareRoute)
          } catch (e) {
            this.$swal.insertQueueStep(error)
            return
          }

          fileName = data.data.data
          this.$swal.insertQueueStep(download)
        },
      }
      const error = {
        icon: 'error',
        title: 'Error',
        text: this.$t('notifications.download.error'),
      }
      const download = {
        icon: 'success',
        title: this.$t('notifications.download.downloading'),
        allowOutsideClick: () => !this.$swal.isLoading(),
        onOpen: async () => {
          this.$swal.showLoading()
          let response = false
          try {
            response = await this.$http.get(downloadRoute + fileName, {
              responseType: 'blob',
            })
          } catch (e) {
            // console.log('Cant download file', e)
            await this.$swal.queue([retry])
            return
          }

          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()

          this.$swal.close()
        },
      }
      const retry = {
        icon: 'error',
        title: 'Error',
        text: this.$t('notifications.download.failed'),
        confirmButtonText: this.$t('notifications.download.retry'),
        allowOutsideClick: true,
        preConfirm: () => {
          this.$swal.insertQueueStep(download)
        },
      }

      this.$swal.queue([prepare])
    },
  },
}
</script>
